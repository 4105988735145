<template>
    <b-card no-body class="p-2">
        <div class="row">
            <div class="col-md-8">
                <div class="row mb-2">
                    <div class="col-md-6">
                        <div class="flex items-center">
                            <span style="min-width: 80px;">Tìm kiếm:</span>
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                :placeholder="$t('lbl_search')" debounce="500" @update="handleGetListData" />
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                        <SelectInventory @onChangeInventory="handleChangeInventory" />
                    </div> -->
                </div>
            </div>
        </div>

        <b-overlay :show="isLoading" rounded="sm" spinner-variant="primary" spinner-type="grow" spinner-small>
            <b-table ref="refProductsTable" class="position-relative" :items="dataList" responsive
                :fields="tableColumns" primary-key="id" show-empty :empty-text="$t('lbl_no_data')"
                tbody-class="cs-table cursor-pointer" @row-clicked="handleViewDetail">
                <template #cell(date_add)="data">
                    <span class="text-capitalize">{{ formatDateTime(data.item.date_add) }}</span>
                </template>
                <template #cell(created_at)="data">
                    <span class="text-capitalize">{{ formatDateTime(data.item.created_at) }}</span>
                </template>
                <template #cell(day_contract)="data">
                    <span class="text-capitalize">{{ formatDate(data.item.day_contract) }}</span>
                </template>
                <template #cell(supplier)="data">
                    <span class="text-capitalize">{{ getSupplierLabel(data.item) }}</span>
                </template>
                <template #cell(customer_name)="data">
                    <span class="text-capitalize">{{ data.item.ecom_customer && data.item.ecom_customer.name }}</span>
                </template>
                <template #cell(price_out)="data">
                    <span class="text-capitalize">{{ formatNumber(data.item.price_out) }}</span>
                </template>
                <template #cell(expired_date)="data">
                    <span class="text-capitalize">{{ formatExpiredDate(data.item) }}</span>
                </template>
                <template #cell(receiving_type)="data">
                    <span class="text-capitalize">
                        {{ $t(data.item.receiving_type === 2 ? "lbl_add_begin" : "lbl_import_receiving") }}</span>
                </template>
            </b-table>
        </b-overlay>

        <div class="mx-2 mb-2">
            <b-row>
                <b-col cols="12" sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted">
                        {{ $t("fm_pagination", { from: dataMeta.from, to: dataMeta.to, total: dataMeta.of, }) }}
                    </span>
                </b-col>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination v-model="currentPage" :total-rows="totalProducts" :per-page="perPage" first-number
                        last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import appUtils from "@/utils/appUtils"
import SelectInventory from "@/components/SelectInventory/index.vue"
import vSelect from "vue-select"
import i18n from "@/libs/i18n"

// const statusOptions = [
//     {
//         label: i18n.t("obj_payment_status.not_payment"),
//         value: 1,
//     },
//     {
//         label: i18n.t("obj_payment_status.paid"),
//         value: 2,
//     },
//     {
//         label: i18n.t("obj_payment_status.canceled"),
//         value: 3,
//     },
// ]

// const paymentOptions = [
//     {
//         label: i18n.t("obj_saling_status.not_saling"),
//         value: 1,
//     },
//     {
//         label: i18n.t("obj_saling_status.saling"),
//         value: 2,
//     },
// ]

const tableReceivingColumns = [
    {
        key: "stock_in_code",
        label: i18n.t("obj_table_receiving.receiving_code"),
        sortable: false,
    },
    {
        key: "name",
        label: i18n.t("Tên phiếu"),
        sortable: false,
    },
    // {
    //     key: "supplier",
    //     label: i18n.t("obj_table_receiving.supplier"),
    //     sortable: false,
    // },
    // {
    //     key: "invoice_code",
    //     label: i18n.t("obj_table_receiving.invoice_code"),
    //     sortable: false,
    // },
    // {
    //   key: "day_contract",
    //   label: i18n.t("obj_table_receiving.day_contract"),
    //   sortable: false
    // },
    // {
    //   key: "receiver_name",
    //   label: i18n.t("obj_table_receiving.receiver_name"),
    //   sortable: false
    // },
    {
        key: "created_at",
        label: i18n.t("obj_table_receiving.date_add"),
        sortable: false,
    },
]

export default {
    name: "Indication",
    props: {
        // type: Number
    },
    // components: { vSelect, SelectInventory },
    data() {
        return {
            isLoading: false,
            dataList: [],
            currentPage: 1,
            perPage: 15,
            totalProducts: 0,
            searchQuery: "",
            inventories: [],
            inventoryInfo: null,
            // statusOptions,
            // paymentOptions,
            status: null,
            payment_status: null,
        }
    },
    computed: {
        dataMeta() {
            const localItemsCount = this.dataList?.length || 0
            return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.totalProducts,
            }
        },
        tableColumns() {
            return tableReceivingColumns
        },
    },
    watch: {
        currentPage() {
            // this.handleGetListData()
        },
        $route() {
            // this.handleCheckStatus()
        },
    },
    mounted() {
        // this.handleCheckStatus()
    },
    methods: {
        handleDebounce: appUtils.debounce(function () {
            // this.handleGetListData()
        }, 1000),
        handleViewDetail(item) {
            // if (!item?.id) return
            // const routerName = "ReceivingDetail"
            // this.$router.push({
            //     name: routerName,
            //     params: {
            //         id: item.id,
            //     },
            // })
        },
        handleAddBill() {
            // const routerName = "AddReceiving"
            // this.$router.push({ name: routerName })
        },
        handleGetListData() {
            // this.handleGetReceivingList()
        },
        async handleGetReceivingList() {
            // try {
            //     const params = {
            //         inventory_id: this.inventoryInfo?.id,
            //         page_num: this.currentPage,
            //         page_size: this.perPage,
            //         sort_by: "id",
            //         order: "desc",
            //         keyword: this.searchQuery,
            //     }

            //     const response = await this.$store.dispatch(
            //         "stockIn/fetchStockIn",
            //         params
            //     )

            //     // TODO: Update when implement api
            //     // const response = {};
            //     this.dataList = response.data?.data || []
            //     this.totalProducts = response.data?.page?.total || 0
            // } catch (error) {
            //     console.log(error)
            //     this.$toast({
            //         component: ToastificationContent,
            //         props: {
            //             title: this.$t("lbl_error_data"),
            //             icon: "AlertTriangleIcon",
            //             variant: "danger",
            //         },
            //     })
            // }
        },
        formatDate(date) {
            return window.moment(date).format("DD/MM/YYYY")
        },
        formatDateTime(date) {
            return window.moment(date).format("HH:mm DD/MM/YYYY")
        },
        getSupplierLabel(data) {
            // return data?.product_provider?.name
        },
        handleChangeInventory(data) {
            // this.inventoryInfo = data
            // this.handleGetListData()
        },
        handleChangeStatus(status) {
            // this.handleGetSalingList()
        },
        handleCheckStatus() {
            // if (!this.$route.query.status) return

            // this.status = Number(this.$route.query.status)
        },
        handleChangePaymentStatus(status) {
            // this.handleGetSalingList()
        },
    },
}
</script>

<style lang="scss" scoped></style>